#login {
    height: unset !important;
}

#login>.container {
    margin-top: 187px;
    margin-bottom: 58px;
}

.password {
    margin-top: 10px;
    background-color: rgba(54, 189, 252, 0.722);
    color: rgb(49, 52, 122);
    border-radius: 5px;
    outline: none;
    border: none;
    height: 30px;
    width: 125px;
    font-size: 10;
}


