.test {
    background-color: blanchedalmond
}

.some {
    margin-left: 40px;
    margin-right: 10px;
}

.modalHeader {
    background-image: linear-gradient(to right, rgb(36, 9, 148), rgb(109, 10, 223));
}