.emailVerificationAlert {
    position: fixed;
    right: 0;
    left: 0;
    bottom: 0;
    z-index: 100;
    padding: 5px;
    background-color: rgb(255, 255, 0);
    color: grey;
    display: flex;
    justify-content: center;
    cursor: pointer;
}