.current a {
    color: green !important;
}

/* CSS */
.button-72 {
    align-items: center;
    background-color: initial;
    background-image: linear-gradient(rgb(132 175 201 / 84%), rgb(31 88 91 / 84%) 50%);
    border-radius: 42px;
    border-width: 0;
    box-shadow: rgb(31 88 91 / 24%) 0 2px 2px, rgb(132 182 201 / 40%) 0 8px 12px;
    color: #FFFFFF;
    cursor: pointer;
    display: flex;
    font-family: Quicksand, sans-serif;
    font-size: 18px;
    font-weight: 700;
    justify-content: center;
    letter-spacing: .04em;
    line-height: 16px;
    margin: 0 auto;
    padding: 18px 18px;
    text-align: center;
    text-decoration: none;
    text-shadow: rgb(255 255 255 / 40%) 0 0 4px, rgb(255 255 255 / 20%) 0 0 12px, rgb(31 74 91 / 60%) 1px 1px 4px, rgb(31 63 91 / 32%) 4px 4px 16px;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    vertical-align: baseline;
}

.button-72 a:hover {
    background-image: linear-gradient(#B384C9, #391F5B 50%);
}

@media (min-width: 768px) {
    .button-72 a {
        font-size: 21px;
        padding: 18px 34px;
    }
}


.button-73 {

    align-items: center;
    background-color: initial;
    background-image: linear-gradient(rgb(132 146 201 / 84%), rgb(32 31 91 / 84%) 50%);
    border-radius: 42px;
    border-width: 0;
    box-shadow: rgb(31 38 91 / 24%) 0 2px 2px, rgb(132 137 201 / 40%) 0 8px 12px;
    color: #FFFFFF;
    cursor: pointer;
    display: flex;
    font-family: Quicksand, sans-serif;
    font-size: 18px;
    font-weight: 700;
    justify-content: center;
    letter-spacing: .04em;
    line-height: 16px;
    margin: auto;
    padding: 18px 18px;
    text-align: center;
    text-decoration: none;
    text-shadow: rgb(255 255 255 / 40%) 0 0 4px, rgb(255 255 255 / 20%) 0 0 12px, rgb(31 36 91 / 60%) 1px 1px 4px, rgb(31 33 91 / 32%) 4px 4px 16px;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    vertical-align: baseline;
}

.button-73 a:hover {
    background-image: linear-gradient(#c9b984, #5b551f 50%);
}

@media (min-width: 768px) {
    .button-73 a {
        font-size: 21px;
        padding: 18px 34px;
    }
}